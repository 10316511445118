import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import SlidePanel from "../SlidePanel";


class Tabbar extends Component {

  state = {
    isPanelOpen:false
  }

  render() {

    return (
      <React.Fragment>
        <div className={"row tabbar-list text-center mobile"}>
          <div className={"col tabbar-item"}>
            <NavLink exact className="nav-link menu-center"  to={"/"}>
              <FontAwesome name={"home home-size"} />
            </NavLink>
          </div>
          <div className={"col tabbar-item"}>
            <NavLink  className="nav-link menu-center"  to={"/communities"}>
              <FontAwesome name={"users"} />
            </NavLink>
          </div>
          <div className={"col tabbar-item"}>

            <a  className="nav-link plus-new " onClick={event => {
              event.preventDefault();
              this.setState({
                isPanelOpen:true
              })
            }}>
                <FontAwesome name={"plus plus-size"} />
            </a>

          </div>
          <div className={"col tabbar-item"}>
            <NavLink className="nav-link menu-center" to={"/notification/likes"}>
              <FontAwesomeIcon icon={faHeart} />
            </NavLink>
          </div>
          <div className={"col tabbar-item"}>
            <NavLink
              href={""}
              className={"nav-link menu-center"}
              to={"/my-profile/highlights"}
            >
              <img
                className="rounded-circle rounded-circle-img "
                src={
                  this.props.settings.mainUrl +
                  "/storage/" +
                  this.props.user.avatar
                }
                width={30}
              />
            </NavLink>
          </div>
        </div>
        <SlidePanel isOpen={this.state.isPanelOpen} closePanel={() => {
          this.setState({isPanelOpen:false})
        }}/>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
export default connect(mapStateToProps)(Tabbar);
