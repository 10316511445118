import  React from 'react';
import FontAwesome from "react-fontawesome";
import {withRouter} from 'react-router-dom'

function NotFoundBlock(props) {
  return <React.Fragment>
    <div className={'not-found-block'}>
      <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
        <div className={" p-0 d-flex justify-content-center"}>
          <div
            className={
              "d-flex align-items-center text-center justify-content-center"
            }
          >
            <div className={'main-content'}>
              <img src={require('../../assets/img/No-Content.png')}  />
              <p>Hmm... nothing here yet...<br/>In the mean time: </p>
            </div>
          </div>
        </div>
      </div>
      <div className={'alternative-links row'}>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              <FontAwesome name={'user'}/>
            </div>
            <p>
              Check out all the cool people on Witit.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/communities')
            }} className={'btn btn-primary btn-full-width'}>
              Communities
            </button>
          </div>
        </div>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              <FontAwesome name={'fire'}/>
            </div>
            <p>
              Find great content on the trending page.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/trending/feed')
            }}  className={'btn btn-primary btn-full-width'}>
              Trending
            </button>
          </div>
        </div>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              <FontAwesome name={'plus-circle'}/>
            </div>
            <p>
              Create a new post to share with the world.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/create-post')
            }}  className={'btn btn-primary btn-full-width'}>
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}
export default withRouter(NotFoundBlock)