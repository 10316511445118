import React from 'react';
import {withRouter} from 'react-router-dom';

function CreatePostAndServiceNavbar(props) {
  return <React.Fragment>
    <div className={'row mb-2 service-post-nav'} style={{
      marginLeft: -16,
      marginRight: -16
    }}>
      <div className={'col-6'}>
        <button style={{fontSize:21}} className={[
          'btn',
          'btn-large',
          'btn-full-width',
          props.type === 'post' ? 'btn-primary' : 'btn-light'
        ].join(' ')} type={'button'} onClick={() => {
          props.history.push('/create-post')
        }}>Create a Post</button>
      </div>
      <div className={'col-6'}>
        <button style={{fontSize:21}} className={[
          'btn',
          'btn-large',
          'btn-full-width',
          props.type === 'service' ? 'btn-primary' : 'btn-light'
        ].join(' ')} type={'button'} onClick={() => {
          props.history.push('/create-service')
        }}>List a Service</button>
      </div>
    </div>
  </React.Fragment>
}
export default withRouter(CreatePostAndServiceNavbar);