import React, {Component} from "react";
import DesktopSidebar from "../containers/AccountSettings/DesktopSidebar";

class AccountLayout extends Component{
    render() {
        return (
            <React.Fragment>
                <div className="py-5">
                    <div className="container">
                        <div className="row">

                            <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 ">
                                <div className="desktop">
                                   <DesktopSidebar />
                                </div>

                            </aside>
                            <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12">
                                <div className="box border rounded bg-white mb-3">


                                    {this.props.children}
                                </div>

                            </main>
                            <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">

                            </aside>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
export default AccountLayout;