import React, { Component } from "react";
import LoginRegistration from "../../layouts/LoginRegistration";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { resetForgotPassword } from "./../../redux/actions/userActions";

class ResetForgotPassword extends Component {
  state = {
    isError: false,
    formData: {
      newPassword: "",
      newPassword_c: "",
      email: "",
      token: "",
    },
    message: {
      text: "",
      type: "",
    },
    errors: {
      newPassword: [],
      newPassword_c: [],
    },
  };
  componentDidMount() {
    // console.log(this.props.match.params);
    this.setState({
      formData: {
        ...this.state.formData,
        token: this.props.match.params.token,
        email: this.props.match.params.email,
      },
    });
  }

  validateForm = () => {
    let errors = {
      currentPassword: [],
      newPassword: [],
      newPassword_c: [],
    };

    if (this.state.formData.newPassword === "") {
      errors.newPassword.push(<div>new password must be required!</div>);
    }
    if (this.state.formData.newPassword.length < 8) {
      errors.newPassword.push(
        <div>new password must be min length 8 characters</div>
      );
    }
    if (this.state.formData.newPassword_c === "") {
      errors.newPassword_c.push(<div>new password C must be required!</div>);
    }
    if (this.state.formData.newPassword !== this.state.formData.newPassword_c) {
      errors.newPassword_c.push(
        <div>new password and new password c must be the same!</div>
      );
    }
    return errors;
  };

  checkErrors = (errors) => {
    let isError = false;
    Object.keys(errors).map((objectKey, index) => {
      let value = errors[objectKey];
      if (value.length > 0) {
        isError = true;
      }
    });
    return isError;
  };

  render() {
    return (
      <React.Fragment>
        <LoginRegistration>
          <div className="login-page position-relative">
            <div className="text-content">
              <div className="row">
                <div className="col-lg-7 content"></div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-header text-center">
                <img src={require("../../assets/img/logo.png")} />
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let errors = this.validateForm();
                  let isErrors = this.checkErrors(errors);
                  if (!isErrors) {
                    this.setState(
                      {
                        isErrors: false,
                      },
                      () => {
                        this.props
                          .resetForgotPassword(this.state.formData)
                          .then((response) => {
                            this.setState({
                              message: {
                                text: response.data.message,
                                type: "primary",
                              },
                            });
                          })
                          .catch((error) => {
                            this.setState({
                              message: {
                                text: error.response.data.message,
                                type: "primary",
                              },
                            });
                          });
                      }
                    );
                  } else {
                    this.setState({ isErrors: true, errors: { ...errors } });
                  }
                }}
              >
                {this.state.message.length != 0 ? (
                  <Alert variant={this.state.message.type}>
                    {this.state.message.text}
                  </Alert>
                ) : null}

                <div
                  className={[
                    "form-group",
                    this.state.errors.newPassword.length > 0 &&
                    this.state.isErrors
                      ? "error"
                      : "",
                  ].join(" ")}
                >
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    value={this.state.formData.newPassword}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          newPassword: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className={"errors-messages"}>
                    {this.state.errors.newPassword.length > 0 &&
                    this.state.isErrors
                      ? this.state.errors.newPassword
                      : null}
                  </div>
                </div>
                <div
                  className={[
                    "form-group",
                    this.state.errors.newPassword_c.length > 0 &&
                    this.state.isErrors
                      ? "error"
                      : "",
                  ].join(" ")}
                >
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Compare New Password"
                    value={this.state.formData.newPassword_c}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          newPassword_c: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className={"errors-messages"}>
                    {this.state.errors.newPassword_c.length > 0 &&
                    this.state.isErrors
                      ? this.state.errors.newPassword_c
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-full-width mb-1"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </LoginRegistration>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {
  return {
    resetForgotPassword: (formData) => {
      return dispatch(resetForgotPassword(formData));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetForgotPassword));
