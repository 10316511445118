import React,{useState} from 'react';
import SlidingPanel from 'react-sliding-panel';
import './../assets/css/slidePanel.css';
import FontAwesome from 'react-fontawesome';
import {NavLink} from 'react-router-dom';

function SlidePanel(props) {
    return <div className={'slide-panel-container black-panel'}>
        <SlidingPanel type={'bottom'} isOpen={props.isOpen} closeFunc={() => {props.closePanel()}} className={'black'}>
            <div className={'header-panel'}>
                <a href={''} onClick={event => {
                    event.preventDefault();
                    props.closePanel();
                }}>
                    <FontAwesome name={'chevron-down'}/>
                </a>
                <span className={'title'}>Create a New</span>
                <span></span>
            </div>
            <div className={'body-panel '}>
                <div className={'icons-navigation row justify-content-center'}>
                    <div className={'col-4 text-center '}>
                        <NavLink className={'nav-link'} to={''}>
                            <FontAwesome classname={'icon'} name={'briefcase'} />
                            <div className={'icon-title'}>Service</div>
                        </NavLink>
                    </div>
                    <div className={'col-4 text-center'}>
                        <NavLink className={'nav-link'} to={''}>
                            <FontAwesome classname={'icon'} name={'camera'} />
                            <div className={'icon-title'}>Post</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </SlidingPanel>
    </div>;
}

export default SlidePanel;