import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

function RequestPayoutForm(props) {
  let [type, setType] = useState("Bank Account");
  let [number, setNumber] = useState("");
  let [sum, setSum] = useState(0);
  let [isShow, setIsShow] = useState(false);
  let types = ["Bank Account", "Card"];
  return (
    <React.Fragment>
      <button
        className={"btn btn-primary"}
        onClick={() => {
          setIsShow(true);
        }}
      >
        Withdraw Money
      </button>
      <Modal
        show={isShow}
        onHide={() => {
          setIsShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"row mb-2"}>
            <div className={"col-12"}>Type Destination</div>
            <div className={"col-12"}>
              <select
                className={"form-control bordered"}
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              >
                {types.map((typeItem, index) => {
                  return (
                    <option value={typeItem} key={`payout_type_${index}`}>
                      {typeItem}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className={"row mb-2"}>
            {" "}
            <div className={"col-12"}>Destination</div>
            <div className={"col-12"}>
              <input
                className={"form-control bordered"}
                value={number}
                onChange={(event) => {
                  setNumber(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>Sum</div>
            <div className={"col-12"}>
              <input
                className={"form-control bordered"}
                type={"number"}
                value={sum}
                onChange={(event) => {
                  setSum(parseFloat(event.target.value));
                }}
                step={0.01}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              let formData = {
                sum: sum,
                type: type,
                number: number,
              };
              props.createPayout(formData).then((response) => {
                setType(types[0]);
                setNumber("");
                setSum(9);
                setIsShow(false);
              });
            }}
          >
            Request
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default RequestPayoutForm;
