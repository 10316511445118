import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import "./FeedContextMenu.css";
import { Modal } from "react-bootstrap";
import { postReport } from "./../../redux/actions/actionReports";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setHighlights } from "./../../redux/actions/postActions";

function FeedContextMenu(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState({
    description: "",
    type: 0,
    type_content:'feed'
  });

  return (
    <React.Fragment>
      <a
        href={""}
        onClick={(e) => {
          e.preventDefault();
          setShowDialog(true);
        }}
      >
        ...
      </a>
      {showDialog ? (
        <div className={"feed-context-menu"}>
          <ul>
            {props.user.id === props.feed.author_id ? (
              <React.Fragment>
                <li>
                  <a
                    href={""}
                    onClick={(event) => {
                      event.preventDefault();
                      props.setHighlights(props.feed.id).then((response) => {
                        setShowDialog(false);
                      });
                    }}
                  >
                    <FontAwesome name={"bookmark"} /> Add to Highlights
                  </a>
                </li>
                <li>
                  <a
                    href={""}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(`/edit-post/${props.feed.id}`);
                    }}
                  >
                    <FontAwesome name={"pencil"} /> Edit Post
                  </a>
                </li>
                <li>
                  <a
                    href={""}
                    className={"danger"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.postDelete(props.feed.id).then((response) => {
                        setShowDialog(false);
                        props.deleteFromList();
                      });
                    }}
                  >
                    <FontAwesome name={"trash"} /> Delete Post
                  </a>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <a
                    href={""}
                    className={"danger"}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowReport(true);
                    }}
                  >
                    <FontAwesome name={"exclamation"} /> Report
                  </a>
                </li>
              </React.Fragment>
            )}

            <li>
              <a
                href={""}
                onClick={(event) => {
                  event.preventDefault();
                  setShowDialog(false);
                }}
              >
                <FontAwesome name={"times"} /> Close
              </a>
            </li>
          </ul>
        </div>
      ) : null}

      <Modal show={showReport}>
        <Modal.Body className={"report-modal"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <label className={"mb-4 d-block"}>
                What seems to be the problem?
              </label>

              <label className={"d-block"}>
                <input
                  type={"radio"}
                  name={"type-problem"}
                  checked={reportData.type === 0}
                  className={"mr-2"}
                  value={0}
                  onChange={(event) => {
                    setReportData({
                      ...reportData,
                      type: parseInt(event.target.value),
                    });
                  }}
                />
                <span>Inappropriate service</span>
              </label>
              <label className={"d-block"}>
                <input
                  type={"radio"}
                  name={"type-problem"}
                  className={"mr-2"}
                  value={1}
                  checked={reportData.type === 1}
                  onChange={(event) => {
                    setReportData({
                      ...reportData,
                      type: parseInt(event.target.value),
                    });
                  }}
                />
                <span>I feel scammed</span>
              </label>

              <label className={"d-block mb-4"}>
                <input
                  type={"radio"}
                  name={"type-problem"}
                  className={"mr-2"}
                  value={2}
                  checked={reportData.type === 2}
                  onChange={(event) => {
                    setReportData({
                      ...reportData,
                      type: parseInt(event.target.value),
                    });
                  }}
                />
                <span>Never receive service</span>
              </label>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>
              <label className={"mb-4 d-block"}>Describe the problem:</label>
              <textarea
                placeholder={"Write about the problem here..."}
                className={"form-control p-0 mb-5"}
                value={reportData.description}
                onChange={(e) => {
                  setReportData({
                    ...reportData,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={"row d-flex justify-content-between"}>
            <div className={"col-auto"}>
              <button
                className={"btn btn-dark"}
                onClick={() => {
                  setShowReport(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div className={"col-auto"}>
              <button
                className={"btn btn-danger"}
                onClick={() => {
                  props
                    .postReport(reportData, props.feed.id)
                    .then((response) => {
                      setShowReport(false);
                      setShowDialog(false);
                    });
                }}
              >
                Report
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    postReport: (formData, id_post) => {
      return dispatch(postReport(formData, id_post));
    },
    setHighlights: (id_post) => {
      return dispatch(setHighlights(id_post));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedContextMenu));
