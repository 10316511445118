import React, { Component } from "react";
import AvatarUploader from "react-avatar-uploader";
import {connect} from "react-redux";

class Registration4 extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={[
            "form-group registration-avatar-uploader-block",
            this.props.formErrors().photo.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <AvatarUploader
            uploadURL={`${this.props.settings.mainUrl}/api/avatarUpload`}
            style={{ margin: "o auto" }}
            name={"image"}
            fileType={"image/jpeg, image/png, image/gif"}
            onFinished={(err, res) => {
              if (res.data !== "") {
                this.props.onChangePhoto(res.data);
              }
            }}
          />

          <label className={"text-center"} style={{ width: "100%" }}>
            Add a Profile Photo
          </label>
          <div className={"errors-messages"}>
            {this.props.formErrors().photo.length > 0 && this.props.isError
              ? this.props.formErrors().photo
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().title.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <label>Add a title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Ex: Photographer, Netflix Binger, etc."
            maxLength={20}
            onChange={(e) => {
              this.props.onChangeTitle(e);
            }}
            value={this.props.formData.title}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().title.length > 0 && this.props.isError
              ? this.props.formErrors().title
              : null}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-full-width"
              onClick={this.props.nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return{
    settings: state.settings,
  }
}
export default connect(mapStateToProps)(Registration4) ;
