import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faChevronRight, faUserPlus} from "@fortawesome/free-solid-svg-icons";

class DesktopSidebar extends Component{
    render() {
        return(
            <React.Fragment>
                <div className={'desktop'}>
                    <ul className="list-group list-group-flush list-group-no-border">
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                               <FontAwesomeIcon icon={faUserPlus} className={'mr-2'}/> {" "}Invite Friends
                                <span className="ml-auto font-weight-bold">
                                   <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <FontAwesomeIcon icon={faUserPlus} className={'mr-2'}/> Account{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-user-check mr-2 text-dark" /> Rates{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-message-circle mr-2 text-dark" /> Payments{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-clipboard mr-2 text-dark" /> Badges{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-tag mr-2 text-dark" /> Privacy{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-tag mr-2 text-dark" /> Help{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                        <a href="#">
                            <li className="list-group-item pl-3 pr-3 d-flex align-items-center text-dark font-weight-600">
                                <i className="feather-tag mr-2 text-dark" /> About{" "}
                                <span className="ml-auto font-weight-bold">
                                     <FontAwesomeIcon icon={faChevronRight} />
                                  </span>
                            </li>
                        </a>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default DesktopSidebar;