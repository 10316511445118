import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import NotFoundBlock from "./NotFoundBlock";

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <DesktopNavbar />
          <MobileNavbar />
          <BaseLayout>
            <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile "></aside>
            <main
              className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
              style={{ padding: "0 8px" }}
            >
              <NotFoundBlock/>
            </main>
            <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12"></aside>
          </BaseLayout>
          <Tabbar />
        </React.Fragment>
      </React.Fragment>
    );
  }
}
export default NotFound;
