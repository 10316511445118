import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";

class ServiceOrderSuccess extends Component {
  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.props.user.name}</h2>
                  <MyProfileSidebar active={""} isOwner={true} />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    isOwner={true}
                    active={{
                      value: "",
                      label: "",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
              <div className={" p-0 d-flex justify-content-center"}>
                <div
                  className={
                    "d-flex align-items-center service-order-result text-center justify-content-center"
                  }
                >
                  <div>Your order has been sent.</div>
                </div>
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServiceOrderSuccess));
