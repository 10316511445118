import React, { Component } from "react";

class Registration1 extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={[
            "form-group",
            this.props.formErrors().firstName.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="First name"
            value={this.props.formData.firstName}
            onChange={(e) => {
              this.props.onChangeFirstName(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().firstName.length > 0 && this.props.isError
              ? this.props.formErrors().firstName
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().lastName.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Last name"
            value={this.props.formData.lastName}
            onChange={(e) => {
              this.props.onChangeLastName(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().lastName.length > 0 && this.props.isError
              ? this.props.formErrors().lastName
              : null}
          </div>
        </div>

        <div
          className={[
            "form-group",
            this.props.formErrors().username.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            value={this.props.formData.username}
            onChange={(e) => {
              this.props.onChangeUsername(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().username.length > 0 && this.props.isError
              ? this.props.formErrors().username
              : null}
          </div>
        </div>

        <div
          className={[
            "form-group",
            this.props.formErrors().password.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={this.props.formData.password}
            onChange={(e) => {
              this.props.onChangePassword(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().password.length > 0 && this.props.isError
              ? this.props.formErrors().password
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().phone.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Phone"
            value={this.props.formData.phone}
            onChange={(e) => {
              this.props.onChangePhone(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().phone.length > 0 && this.props.isError
              ? this.props.formErrors().phone
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().email.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={this.props.formData.email}
            onChange={(e) => {
              this.props.onChangeEmail(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().email.length > 0 && this.props.isError
              ? this.props.formErrors().email
              : null}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-full-width"
              onClick={this.props.nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Registration1;
