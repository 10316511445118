import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";

class PaymentSettings extends Component{
  render(){
    return  <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
          <div className={" row sidebar-menu"}>
            <div className={"col-12 "}>
              <div className={"bg-white profile-account-holder desktop"}>
                <h2>Settings</h2>
                <DesktopSidebarAccountSettings
                  active={"/settings/payments"}
                />
              </div>
              <div className={"mobile"}>
                <MobileSidebarAccountSettings
                  active={{ value: "/settings/payments", label: "Payments" }}
                />
              </div>
            </div>
          </div>
        </aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
          style={{ padding: "0 16px" }}
        >
          <div className="profile-account-holder settings-container bg-white row">
            <div className={"col-12  "}>
              <div className={'d-flex justify-content-between mb-3'}>
                <h1 className={'d-inline-block'} >Payments </h1>
                <a href={''} style={{fontSize:24}}>Edit</a>
              </div>
              <h2>
                Billing Information
              </h2>
              <div className={'billing-information mb-2'}>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>First Name: </div>
                  <div className={'col-9 value'}>Georgia </div>
                </div>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>Last Name: </div>
                  <div className={'col-9 value'}>Hardy </div>
                </div>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>Street Address: </div>
                  <div className={'col-9 value'}>Photographer </div>
                </div>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>Zip Code: </div>
                  <div className={'col-9 value'}>666 </div>
                </div>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>City: </div>
                  <div className={'col-9 value'}>San Francisco</div>
                </div>
                <div className={'row mb-2'}>
                  <div className={'col-3 title'}>State: </div>
                  <div className={'col-9 value'}>California </div>
                </div>
                <div className={'row mb-3'}>
                  <div className={'col-3 title'}>Country: </div>
                  <div className={'col-9 value'}>United States </div>
                </div>

                <div className={'row justify-content-between mb-3'}>
                  <div className={'col-auto'}>
                    <div>Credit Card Number</div>
                    <div className={'value'}>8888 8888 8888 8888</div>
                  </div>
                  <div className={'col-auto'}>
                    <div>Expiration Date</div>
                    <div className={'value'}>01/2020</div>
                  </div>
                  <div className={'col-auto'}>
                    <div>Security Code</div>
                    <div className={'value'}>999</div>
                  </div>
                </div>
                <h2>Banking Information</h2>
                <div className={'row  mb-3'}>
                  <div className={'col-6'}>
                    <div>Routing Number</div>
                    <div className={'value'}>88888888</div>
                  </div>
                  <div className={'col-6'}>
                    <div>Account Number</div>
                    <div className={'value'}>0000000</div>
                  </div>
                </div>
              </div>
              <div className={"text-center "}>
                By placing the order you agree to the Witit{" "}
                <a href={""}>Terms of Service</a>
              </div>


            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
          <MyProfileUserBlock user={this.props.user} />
        </aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps,mapDispatchToProps)(PaymentSettings) ;