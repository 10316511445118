import React from "react";
import BounceLoader from 'react-spinners/BounceLoader';


function Loader(props) {
    return <React.Fragment>
        {props.loading ? <div className={'page-loader'}>
            <BounceLoader size={50} color={'#f00'} loading={true} />
        </div> : null}

    </React.Fragment>
}
export default Loader;