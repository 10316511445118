import React, { Component } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

class Registration3 extends Component {
  state = {
    tagifyWhitelist: [
      { id: 100, value: "kenny", title: "Kenny McCormick" },
      { id: 101, value: "cartman", title: "Eric Cartman" },
      { id: 102, value: "kyle", title: "Kyle Broflovski" },
      { id: 103, value: "token", title: "Token Black" },
      { id: 104, value: "jimmy", title: "Jimmy Valmer" },
      { id: 105, value: "butters", title: "Butters Stotch" },
      { id: 106, value: "stan", title: "Stan Marsh" },
      { id: 107, value: "randy", title: "Randy Marsh" },
      { id: 108, value: "Mr. Garrison", title: "POTUS" },
      { id: 109, value: "Mr. Mackey", title: "M'Kay" },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={[
            "form-group",
            this.props.formErrors().account_type.length > 0 &&
            this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <label>Account type:</label>
          <select
            className="form-control"
            value={this.props.formData.account_type}
            onChange={(e) => {
              this.props.onChangeAccountType(e);
            }}
          >
            <option value={1}>Personal</option>
          </select>
          <div className={"errors-messages"}>
            {this.props.formErrors().account_type.length > 0 &&
            this.props.isError
              ? this.props.formErrors().account_type
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().expertise.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <label>Expertise:</label>
          <select
            className="form-control"
            value={this.props.formData.expertise}
            onChange={(e) => {
              this.props.onChangeExpertise(e);
            }}
          >
            <option value={1}>Netflix Binger</option>
          </select>
          <div className={"errors-messages"}>
            {this.props.formErrors().expertise.length > 0 && this.props.isError
              ? this.props.formErrors().expertise
              : null}
          </div>
        </div>
        <div className="form-group ">
          <label>Skills (Optional)</label>
          <Tags
            className="form-control"
            placeholder="Gender"
            rows={4}
            onChange={(e) => {
              this.props.onChangeSkills(e);
            }}
            value={this.props.formData.skills}
            settings={{
              whitelist: [...this.state.tagifyWhitelist],
              // mode: "mix",
              // pattern: /@/,
              // dropdown: {
              //     enabled: 1,
              //     position: "text"
              // },
            }}
          />
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-full-width"
              onClick={() => {
                this.props.nextPage();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Registration3;
