import React, { Component, useCallback } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import AvatarUploader from "react-avatar-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faFire,
  faHeart,
  faMapMarkerAlt,
  faPaperPlane,
  faPlusCircle,
  faStar,
  faUser,
  faImages,
  faVideo,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import Tabbar from "../../components/headers/Tabbar";
import BaseLayout from "../../layouts/BaseLayout";
import Switch from "react-switch";
import FontAwesome from "react-fontawesome";
import { createPost, isVideoFile,numbersVideoPostLast24Hours } from "./../../redux/actions/postActions";
import { fetchAllPostCategories } from "./../../redux/actions/postCategory";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Alert } from "react-bootstrap";
import CreatePostAndServiceNavbar from "../../components/CreatePostAndServiceNavbar";

class CreatePost extends Component {
  state = {
    formData: {
      exhibit: false,
      type: "photo",
      mature: false,
      price: 0,
      paid: false,
      category: "",
      image: "",
      caption: "",
      highlight: false,
      description: "",
      video: "",
      image1: "",
      image2: "",
      image3: "",
      image4: "",
    },
    categories: [],
    loading: false,
    isError: false,
    error: "",
  };

  componentDidMount() {
    this.props.fetchAllPostCategories().then((response) => {
      this.setState({
        categories: [...response.data],
      });
    });
  }

  validateForm = async () => {
    let message = [];
    if (
      this.state.formData.type === "photo" &&
      this.state.formData.image === ""
    ) {
      message.push("Photo is required!");
    }
    if (
      this.state.formData.type === "video" &&
      this.state.formData.video === ""
    ) {
      message.push("Video is required!");
    }
    if(this.state.formData.category === ''){
      message.push("Category is required!");
    }

    let response = await this.props.numbersVideoPostLast24Hours();

    if(response.data > 2){
      message.push("Еxceeded 2 video per day limit!");
    }

    return message.join('<br>');
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Uploading video..."
          >
            <p>Some content or children or something.</p>
          </LoadingOverlay>
        ) : null}

        <DesktopNavbar />
        <MobileNavbar > </MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-2 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            {" "}
          </aside>
          <main
            className="col col-xl-8 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 create-post"
            style={{ padding: "0 8px" }}
          >
            <CreatePostAndServiceNavbar type={'post'}/>
            <div className="profile-account-holder row  bg-white">
              <div className={"col-12"}>
                <h2>Create a Post</h2>
                <div
                  className={
                    "d-flex justify-content-between align-items-center row mb-3"
                  }
                >
                  <div
                    className={
                      "col-lg col-md-12 d-flex align-items-center mb-2"
                    }
                  >
                    <label
                      className={"image-video-radio mr-3"}
                      htmlFor={"image-type"}
                    >
                      <input
                        type={"radio"}
                        id={"image-type"}
                        name={"post-type"}
                        checked={this.state.formData.type === "photo"}
                        value={"photo"}
                        onClick={(e) => {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              type: e.target.value,
                            },
                          });
                        }}
                      />{" "}
                      <span className={"text"}>
                        <FontAwesomeIcon icon={faImages} className={"mr-3"} />{" "}
                        Photo
                      </span>
                    </label>

                    <label
                      className={"image-video-radio mr-3"}
                      htmlFor={"video-type"}
                    >
                      <input
                        type={"radio"}
                        id={"video-type"}
                        name={"post-type"}
                        value={"video"}
                        onClick={(e) => {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              type: e.target.value,
                            },
                          });
                        }}
                        checked={this.state.formData.type === "video"}
                      />
                      <span className={"text"}>
                        <FontAwesomeIcon icon={faVideo} className={"mr-3"} />{" "}
                        Video
                      </span>
                    </label>
                  </div>

                  <div className={"col-md col-sm col-12 mb-2"}>
                    <span
                      className={"mr-3 d-inline-block"}
                      style={{ opacity: this.state.formData.exhibit ? 1 : 0.5 }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faLock} className={"mr-3"} />
                      Exhibit
                    </span>
                    <Switch
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      height={10}
                      width={25}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            exhibit: e,
                          },
                        });
                      }}
                      checked={this.state.formData.exhibit}
                      className="react-switch mr-3"
                    />
                    <input
                      className={"form-control bordered"}
                      type={"number"}
                      value={this.state.formData.price}
                      step="0.01"
                      readOnly={!this.state.formData.exhibit}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            price: parseFloat(e.target.value),
                          },
                        });
                      }}
                      placeholder={"$0"}
                      style={{ width: 70 }}
                    />
                  </div>

                  <div className={"col-lg-auto col-sm-auto col-12 mb-2"}>
                    <select
                      className={"form-control bordered"}
                      value={this.state.formData.category}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            category: e.target.value,
                          },
                        });
                      }}
                    >
                      <option value={0}>Select a category</option>
                      {this.state.categories.map((category, index) => {
                        return (
                          <React.Fragment key={`category-post-${index}`}>
                            {
                              !(category.mature === 1 && this.props.user.is_adult_show === 0) ? <option value={category.id}> {category.name}</option> : null
                            }

                          </React.Fragment>

                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col"}>
                    {this.state.isError ? (
                      <Alert variant={"danger"} >
                        <div dangerouslySetInnerHTML={{__html: this.state.error}}  /></Alert>
                    ) : null}
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-lg-auto text-center avatar-uploader-block"}
                  >
                    {this.state.formData.type === "photo" ? (
                      <AvatarUploader
                        uploadURL={
                          this.props.settings.mainUrl + "/api/imageUpload"
                        }
                        style={{ margin: "o auto" }}
                        name={"image"}
                        fileType={"image/jpeg, image/png, image/gif"}
                        onFinished={(err, res) => {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              image: res.data,
                            },
                          });
                        }}
                      />
                    ) : null}
                    {this.state.formData.type === "video" ? (
                      <input
                        type={"file"}
                        accept={"video/mp4,video/x-m4v,video/*"}
                        onChange={(event) => {
                          let isVideo = this.props.isVideoFile(
                            event.target.value
                          );
                          console.log(event.target.files[0].size)
                          if (isVideo) {
                            let data = new FormData();
                            data.append("api_token", this.props.user.api_token);
                            data.append("fileName", event.target.files[0]);

                            this.setState(
                              {
                                loading: true,
                              },
                              () => {
                                axios
                                  .post(
                                    this.props.settings.mainUrl +
                                      "/api/post/video-upload",
                                    data,
                                    {
                                      headers: {
                                        "content-type": "multipart/form-data",
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    this.setState(
                                      {
                                        formData: {
                                          ...this.state.formData,
                                          video: response.data.video,
                                          image: response.data.photo,
                                        },
                                        loading: false,
                                      },
                                      () => {
                                        alert("Video uploaded");
                                      }
                                    );
                                  });
                              }
                            );
                          } else {
                            alert("Selected file is not video!");
                          }
                        }}
                      />
                    ) : null}
                  </div>
                  <div className={"col-lg"}>
                    <input
                      type={"text"}
                      placeholder={"Write a caption ..."}
                      className={"form-control"}
                      rows={4}
                      value={this.state.formData.caption}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            caption: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    "row justify-content-between align-items-center mt-3"
                  }
                >
                  <div className={"col-auto"}>
                    <label
                      htmlFor={"highlights"}
                      className={"btn-checkbox-bordered mr-3 mb-2"}
                    >
                      <input
                        type={"checkbox"}
                        id={"highlights"}
                        checked={this.state.formData.highlight}
                        onChange={() => {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              highlight: !this.state.formData.highlight,
                            },
                          });
                        }}
                      />
                      <span className={"d-inline-block"}>
                        Add to Highlights
                      </span>
                    </label>
                    {
                      this.props.user.is_adult_make ? <label
                        htmlFor={"mature"}
                        className={"btn-checkbox-bordered mr-3 mb-2"}
                      >
                        <input
                          type={"checkbox"}
                          id={"mature"}
                          checked={this.state.formData.mature}
                          onChange={() => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                mature: !this.state.formData.mature,
                              },
                            });
                          }}
                        />
                        <span className={"d-inline-block"}>Mature Content</span>
                      </label> : null
                    }


                    <label
                      htmlFor={"tips"}
                      className={"btn-checkbox-bordered mr-3 mb-2"}
                    >
                      <input type={"checkbox"} id={"tips"} />
                      <span className={"d-inline-block"}>Accept Tips</span>
                    </label>

                    <button
                      type={"button"}
                      className={"btn btn-bordered-dark mr-3 mb-2"}
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type={"button"}
                      onClick={() => {
                        this.validateForm().then(error => {
                          if (error.length > 0) {
                            this.setState({
                              isError: true,
                              error: error,
                            });
                          } else {
                            this.setState(
                              {
                                error: "",
                                isError: false,
                              },
                              () => {
                                this.props
                                  .createPost(this.state.formData)
                                  .then((response) => {
                                    this.props.history.push("/my-profile/feed");
                                  });
                              }
                            );
                          }
                        });

                      }}
                      className={"btn btn-primary mb-2"}
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {this.state.formData.type === "photo" ? (
              <React.Fragment>
                <h2 className={"mt-3 mb-3"}>Add more to your post</h2>
                <div className={"profile-account-holder row  bg-white"}>
                  <div className={"col-12"}>
                    <div className={"row"}>
                      <div
                        className={"col-lg-3 col-6 avatar-uploader-block photo-block"}
                      >
                        <AvatarUploader
                          uploadURL={
                            this.props.settings.mainUrl + "/api/imageUpload"
                          }
                          style={{ margin: "o auto", marginBottom: 10 }}
                          name={"image"}
                          fileType={"image/jpeg, image/png, image/gif"}
                          onFinished={(err, res) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                image1: res.data,
                              },
                            });
                          }}
                        />
                      </div>
                      <div
                        className={"col-lg-3 col-6 avatar-uploader-block photo-block"}
                      >
                        <AvatarUploader
                          uploadURL={
                            this.props.settings.mainUrl + "/api/imageUpload"
                          }
                          style={{ margin: "0 auto", marginBottom: 10 }}
                          name={"image"}
                          fileType={"image/jpeg, image/png, image/gif"}
                          onFinished={(err, res) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                image2: res.data,
                              },
                            });
                          }}
                        />
                      </div>
                      <div
                        className={"col-lg-3 col-6 avatar-uploader-block photo-block"}
                      >
                        <AvatarUploader
                          uploadURL={
                            this.props.settings.mainUrl + "/api/imageUpload"
                          }
                          style={{ margin: "0 auto", marginBottom: 10 }}
                          name={"image"}
                          fileType={"image/jpeg, image/png, image/gif"}
                          onFinished={(err, res) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                image3: res.data,
                              },
                            });
                          }}
                        />
                      </div>
                      <div
                        className={"col-lg-3 col-6 avatar-uploader-block photo-block"}
                      >
                        <AvatarUploader
                          uploadURL={
                            this.props.settings.mainUrl + "/api/imageUpload"
                          }
                          style={{ margin: "0 auto", marginBottom: 10 }}
                          name={"image"}
                          fileType={"image/jpeg, image/png, image/gif"}
                          onFinished={(err, res) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                image4: res.data,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className={"col-lg-12 mt-3"}>
                        <textarea
                          placeholder={"Write a more ..."}
                          className={"form-control"}
                          value={this.state.formData.description}
                          onChange={(e) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                description: e.target.value,
                              },
                            });
                          }}
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </main>
          <aside
            className="col col-xl-2 order-xl-3 col-lg-12 order-lg-3 col-12"
            style={{ fontSize: 16 }}
          >
            When posting to Witit, please be sure you own the rights to the
            content you are posting if you are accepting money for the work.
            Also please be mindful of our <a href={""}>content policy</a>.
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return { settings: state.settings, user: state.user.user };
}
function mapDispatchToProps(dispatch) {
  return {
    createPost: (formData) => {
      return dispatch(createPost(formData));
    },
    fetchAllPostCategories: () => {
      return dispatch(fetchAllPostCategories());
    },
    isVideoFile: (fileName) => {
      return dispatch(isVideoFile(fileName));
    },
    numbersVideoPostLast24Hours:() => {
      return dispatch(numbersVideoPostLast24Hours());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatePost));
