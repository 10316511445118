import React, { Component } from "react";
import { connect } from "react-redux";
import { skills } from "../../redux/actions/userActions";
import Select from 'react-select';
import { skillsSelectStyles } from "../../redux/actions/functionActions";

class Registration6 extends Component {
  state = {};

  renderCategories = () => {
    let categories = [];
    categories.push(<option value={""}>Categories</option>);
    for (var i = 0; i < this.props.categories.length; i++) {
      categories.push(
        <option
          key={`category_${i}`}
          value={this.props.categories[i].value}
          data-index={i}
        >
          {this.props.categories[i].label}
        </option>
      );
    }
    return categories;
  };

  renderExpertise = () => {
    let expertise = [];
    expertise.push(<option value={""}>Expertise</option>);
    for (var i = 0; i < this.props.expertise.length; i++) {
      expertise.push(
        <option key={`category_${i}`} value={i} data-index={i}>
          {this.props.expertise[i]}
        </option>
      );
    }
    return expertise;
  };
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className={"row form-group"}>
          <div className={"col-12"}>
            <label>Select up to 3 areas of expertise (Options)</label>
          </div>

          {this.props.formData.skills.map((skill, index) => {
            return (
              <React.Fragment>
                <div className={"col-6 mb-2"}>
                  <Select styles={skillsSelectStyles()} options={[{value:'',label:'Category'},...this.props.categories]} defaultValue={{value:'',label:'Category'}} onChange={e => {
                    this.props.onChangeSkills(e.value, index);
                  }}/>

                </div>
                <div className={"col-6 mb-2"}>
                  <Select options={this.props.expertise} defaultValue={{ value:'', label:"Expertise"}} onChange={e => {
                    this.props.onChangeExpertise(e.value, index);
                  }}/>

                </div>
              </React.Fragment>
            );
          })}
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary btn-full-width">
              Sign Up
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    skills: () => {
      return dispatch(skills());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Registration6);
