export function stylesMobileSidebarSelect() {
  return {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '0',
      color: state.isSelected ? '#fff' : '#000',
      fontSize:16,
      fontWeight:600,
      paddingLeft:35
    }),
    control: (provided, state) => ({
      ...provided,
      textAlign:'center',
      fontWeight: 600,
      fontSize:16,
      backgroundColor:'#fff',
      border:0
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display:'none'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      justifyContent:'center',
      fontSize:16,
      fontWeight:600
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize:16,
      fontWeight:600
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize:16,
      fontWeight:600
    }),
    menu: (provided, state) => {

      return ({
      ...provided,
      position:'static',

    })}
  }
}


export function shortFormNumbers(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "K", "M", "B","T"];
    var suffixNum = Math.floor( (""+value).length/3 );
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
      if (dotLessShortValue.length <= 2) { break; }
    }
    if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
    newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

export function skillsSelectStyles() {
  return {
    groupHeading : (provided, state) => ({
      ...provided,
    fontWeight:700,
      color:'#000'
    })
  };
}