import React, { Component, useEffect, useState } from "react";
import Compose from "../Compose";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import Message from "../Message";
import moment from "moment";
import { connect } from "react-redux";
import {
  addMessage,
  appendMessages,
  verifyMessenger,
} from "./../../../../redux/actions/messengerActions";
import {
  getToken,
  createVideochat,
  downVideochat,
  joinVideohat,
  startVideochat
} from "./../../../../redux/actions/twilioActions";

import "./MessageList.css";
import { connect as twilioConnect } from "twilio-video";
import VideoChat from "../../../Twilio/VideoChat";
import axios from "axios";

class MessageList extends Component {
  state = {
    message: "",
    listScrollable: true,
    pageSize: 20,
    page: 1,
    messages: [],
    videochatToken: null,
  };

  constructor(props) {
    super(props);
    this.messagesListRef = React.createRef();
  }

  renderMessages = () => {
    const MY_USER_ID = this.props.user.id;
    let i = 0;
    let messages = [...this.state.messages];

    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === MY_USER_ID;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          mainUrl={this.props.settings.mainUrl}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

  componentDidMount() {
    this.setState(
      {
        activeConversation: { ...this.props.activeConversation },
      },
      (response) => {}
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(this.props.activeConversation);
    if (this.props.activeConversation.id !== prevProps.activeConversation.id) {
      if (
        Object.keys(this.props.activeConversation).length > 0 &&
        this.props.activeConversation.messenger !== null &&
        this.props.activeConversation.messenger !== undefined &&
        Object.keys(this.props.activeConversation.messenger).length > 0
      ) {
        let messages = [
          ...JSON.parse(this.props.activeConversation.messenger.messages),
        ];
        this.setState({ messages: [...messages] });
      }

      this.setState(
        {
          activeConversation: { ...this.props.activeConversation },
        },
        (response) => {
          var selection = document.getElementById("message-list") !== null;

          if (selection) {
            document.getElementById("message-list").scrollTop =
              document.getElementById("message-list").scrollHeight + 62;
          }
        }
      );
    }

    if (
      Object.keys(this.props.activeConversation).length > 0 &&
      Object.keys(prevProps.activeConversation).length > 0 &&
      this.props.activeConversation.messenger !== null &&
      this.props.activeConversation.messenger !== undefined &&
      prevProps.activeConversation.messenger !== null &&
      prevProps.activeConversation.messenger !== undefined &&
      Object.keys(this.props.activeConversation.messenger).length > 0 &&
      this.props.activeConversation.messenger.updated_at !==
        prevProps.activeConversation.messenger.updated_at
    ) {
      this.setState(
        {
          activeConversation: { ...this.props.activeConversation },
          messages: [
            ...JSON.parse(this.props.activeConversation.messenger.messages),
          ],
        },
        (response) => {}
      );
    }
  }

  onChangeMessageHandler = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  callVideoHandler = async (e, roomName, user) => {
    e.preventDefault();
    return this.props.getToken(roomName, user.name);
  };
  createVideochat = (e, roomName) => {
    this.callVideoHandler(e, roomName, this.props.user).then((response) => {
      this.setState({ videochatToken: response.data.accessToken }, () => {
        this.props
          .createVideochat(this.props.activeConversation.messenger)
          .then((response) => {
            this.props.getConversationWithActive();
          });
      });
    });
  };

  startVideoChat = (e, roomName) => {
    this.callVideoHandler(e, roomName, this.props.user).then((response) => {
      this.setState({ videochatToken: response.data.accessToken }, () => {
        this.props
            .startVideochat(this.props.activeConversation.videochat)
            .then((response) => {
              this.props.getConversationWithActive();
            });
      });
    });
  }

  joinVideochat = (e, roomName) => {
    e.preventDefault();
    this.props
      .joinVideochat(this.props.activeConversation.messenger)
      .then((response) => {
        this.callVideoHandler(e, roomName, this.props.user).then((response) => {
          this.setToken(response.data.accessToken);
        });
      });
  };

  downVideochat = () => {
    if (this.props.activeConversation.videochat !== null) {
      this.props
        .downVideochat(this.props.activeConversation.videochat.id)
        .then((response) => {
          this.setToken(null);
        });
    }

    if (this.props.activeConversation.videochat === null) {
      this.setToken(null);
    }
  };

  setToken = (value) => {
    this.setState({
      videochatToken: value,
    });
  };

  render() {

    return (
      <div
        className="scrollable content position-relative"
        id={"message-list"}
        ref={this.messagesListRef}
        onScroll={(e) => {
          if (
            Object.keys(this.props.activeConversation).length > 0 &&
            this.props.activeConversation.messenger !== null &&
            this.props.activeConversation.messenger !== undefined &&
            e.target.scrollTop === 0 &&
            this.state.listScrollable
          ) {
            this.props
              .appendMessages(
                this.props.activeConversation.messenger.id,
                this.state.page + 1
              )
              .then((response) => {
                let messages = [
                  ...response.data,
                  ...JSON.parse(
                    this.props.activeConversation.messenger.messages
                  ),
                ];
                this.setState({
                  messages: [...messages],
                });
              });
          }
        }}
      >
        <div className="message-list">
          {Object.keys(this.props.activeConversation).length > 0 ? (
            <React.Fragment>
              {
                <Toolbar
                  title={ this.props.activeConversation.full_name }
                  link={'/my-profile/highlights/' + this.props.activeConversation.name}
                  leftItems={[ !this.props.sidebarHide
                    ? null
                    : <ToolbarButton key="arrow-to" icon="ion-ios-arrow-dropright" onClick={this.props.toggleSidebarHide} /> ]}
                  rightItems={[
          //          <ToolbarButton key="video" icon="ion-ios-videocam" />,

                    this.props.activeConversation.messenger !== null && this.props.activeConversation.videochat !== null ? (
                      <VideoChat
                        key={"videochat"}
                        activeConversation={this.props.activeConversation}
                        user={this.props.user}
                        token={this.state.videochatToken}
                        callVideoHandler={this.callVideoHandler}
                        createVideochat={this.createVideochat}
                        setToken={this.setToken}
                        downVideochat={this.downVideochat}
                        joinVideochat={this.joinVideochat}
                        startVideochat={this.startVideoChat}
                      />
                    ) : null,
                  ]}
                />
              }
              <div className="message-list-container">
                {Object.keys(this.props.activeConversation).length > 0 &&
                this.props.activeConversation.messenger !== null &&
                this.props.activeConversation.messenger !== undefined &&
                Object.keys(this.props.activeConversation.messenger).length >
                  0 ? (
                  <React.Fragment>
                    {this.renderMessages()}
                    {this.props.activeConversation.messenger.verified === 0 &&
                    this.props.user.id ===
                      this.props.activeConversation.messenger.id_user2 ? (
                      <div className={"text-center confirm-buttons"}>
                        <span className={"d-block"}>
                          {this.props.activeConversation.full_name} want's to
                          send you message
                        </span>
                        <button
                          className={"btn btn-primary"}
                          style={{ marginRight: 15 }}
                          onClick={() => {
                            this.props
                              .verifyMessenger(
                                this.props.activeConversation.messenger.id,
                                1
                              )
                              .then((response) => {
                                this.props.getConversationWithActive();
                              });
                          }}
                        >
                          Accept
                        </button>
                        <button
                          className={"btn btn-light"}
                          onClick={() => {
                            this.props
                              .verifyMessenger(
                                this.props.activeConversation.messenger.id,
                                -1
                              )
                              .then((response) => {
                                this.props.getConversationWithActive();
                              });
                          }}
                        >
                          Reject
                        </button>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <div className={"text-center"}>
                    <img
                      src={require("./../../../../assets/img/witit-man.png")}
                    />
                  </div>
                )}
              </div>

              <Compose
                value={this.state.message}
                onChangeMessageHandler={this.onChangeMessageHandler}
                rightItems={[
                  <span
                    key="send"
                    onClick={() => {
                      if (
                        this.props.activeConversation.messenger === null ||
                        this.props.activeConversation.messenger === undefined
                      ) {
                        this.props.addMessageHandler({
                          message: this.state.message,
                          sender: this.props.user.id,
                          receiver: this.props.activeConversation.id,
                          date: new Date().getTime(),
                        });
                        this.setState({
                          message: "",
                        });
                      } else {
                        if (
                          this.props.activeConversation.messenger.verified !==
                          -1
                        ) {
                          this.props.addMessageHandler({
                            message: this.state.message,
                            sender: this.props.user.id,
                            receiver: this.props.activeConversation.id,
                            date: new Date().getTime(),
                          });
                          this.setState({
                            message: "",
                          });
                        }
                      }
                    }}
                  >
                    <ToolbarButton icon="ion-ios-send" />
                  </span>,
                  <ToolbarButton key="photo" icon="ion-ios-camera" />,
                  // <ToolbarButton key="image" icon="ion-ios-image" />,
                  // <ToolbarButton key="audio" icon="ion-ios-mic" />,
                  // <ToolbarButton key="money" icon="ion-ios-card" />,
                  // <ToolbarButton
                  //   key="games"
                  //   icon="ion-logo-game-controller-b"
                  // />,
                  // <ToolbarButton key="emoji" icon="ion-ios-happy" />,
                ]}
              />
            </React.Fragment>
          ) : (
            null
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addMessage: (object) => {
      return dispatch(addMessage(object));
    },
    appendMessages: (id_chat, page) => {
      return dispatch(appendMessages(id_chat, page));
    },
    verifyMessenger: (id_chat, value) => {
      return dispatch(verifyMessenger(id_chat, value));
    },
    getToken: (roomName, username) => {
      return dispatch(getToken(roomName, username));
    },
    createVideochat: (messenger) => {
      return dispatch(createVideochat(messenger));
    },
    downVideochat: (videoChat) => {
      return dispatch(downVideochat(videoChat));
    },
    startVideochat: (videoChat) => {
      return dispatch(startVideochat(videoChat));
    },
    joinVideochat: (messenger) => {
      return dispatch(joinVideohat(messenger));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
