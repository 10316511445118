import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlusCircle,
  faStar,
  faFire,
  faUser,
  faStream,
  faDollarSign,
  faHeart,
  faPaperPlane,
  faBars,
  faPowerOff,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../../redux/actions/userActions";


class DesktopNavbar extends Component {

  state = {
    search:''
  };
  render() {
    return (
      <React.Fragment>
        <Navbar
          bg={"white"}
          className={" osahan-nav-top p-0 desktop"}
          variant="dark"
        >
          <div className={"container-fluid"}>
            <div
              className="row no-gutters align-items-center"
              style={{ width: "100%" }}
            >
              <div className="col pl-4">
                <NavLink
                  className="navbar-brand mr-4"
                  to={"/"}
                  style={{ margin: "7px 0" }}
                >
                  <img src={require("../../assets/img/logo.jpg")} />
                </NavLink>
                <form className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    <div className="input-group-append">
                      <button className="btn" type="button" onClick={() => {
                        this.props.history.push(`/search-page/${this.state.search}`)
                      }}>
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control shadow-none border-0"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={this.state.search}
                      onChange={(e) => this.setState({
                        search:e.target.value
                      })}
                    />
                  </div>
                </form>
              </div>

              <div className="col">
                <ul className="navbar-nav ml-auto d-flex align-items-center justify-content-center">
                  {/* Nav Item - Search Dropdown (Visible Only XS) */}
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/create-post"}>
                      <FontAwesomeIcon icon={faPlusCircle} className={"mr-2"} />{" "}
                      Create a Post
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/"}>
                      <FontAwesomeIcon icon={faStar} className={"mr-2"} /> Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link"} to={"/trending-feeds"}>
                      <FontAwesomeIcon icon={faFire} className={"mr-2"} />{" "}
                      Trending
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/communities">
                      <FontAwesomeIcon icon={faUser} className={"mr-2"} />{" "}
                      Communities
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col pr-4">
                <ul className="navbar-nav ml-auto d-flex align-items-center justify-content-end">
                  {/* Nav Item - Search Dropdown (Visible Only XS) */}


                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/messenger");
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </a>
                  </li>

                  <li className="nav-item dropdown no-arrow mx-1 osahan-list-dropdown">
                    <NavLink className="nav-link " to={"/notification/likes"}>
                      <FontAwesomeIcon icon={faHeart} />
                    </NavLink>
                  </li>
                  {/* Nav Item - User Information */}
                  <li className="nav-item dropdown no-arrow ml-1 osahan-profile-dropdown">
                    <a
                      className="nav-link dropdown-toggle pr-0 mr-3"
                      href=""
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/my-profile/highlights/${this.props.user.name}`
                        );
                      }}
                    >
                      <img
                        className="img-profile rounded-circle"
                        src={
                          this.props.settings.mainUrl +
                          "/storage/" +
                          this.props.user.avatar
                        }
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="" style={{ paddingRight: 0 }}  onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        `/settings/general`
                      );
                    }}>
                      <FontAwesomeIcon icon={faBars} style={{ fontSize: 24 }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      return dispatch(logout());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesktopNavbar));
