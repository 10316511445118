import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import FontAwesome from "react-fontawesome";
import { NavLink } from "react-router-dom";

class MobileNavbar extends Component {
  render() {

    return (
      <React.Fragment>
        <div className={"mobile-navbar p-0 mobile bg-white"}>

          <div className="row">
            <div className={"col-3 "}>
              <div className={"d-flex justify-content-between"}>
                <NavLink to={"/settings/general"} className={"nav-item"}>
                  <FontAwesome name={"bars"} />
                </NavLink>
              </div>
            </div>
            <div className={"col-6 d-flex justify-content-center align-items-center"}>
              <div style={{fontWeight:600, fontSize:16}}>
                {this.props.children === undefined ? <React.Fragment>
                  <NavLink
                    className="nav-item"
                    to={"/"}

                  >
                    <FontAwesome name={"home"} />

                  </NavLink>
                  <NavLink
                    className="nav-item"
                    to={"/trending-feeds"}

                  >
                    <FontAwesome name={'fire'} />

                  </NavLink>
                </React.Fragment>  : this.props.children}


              </div>
            </div>
            <div className={"col-3"}>
              <div className={" d-flex justify-content-end"}>
                <NavLink to={"/messenger"} className={"nav-item"}>
                  <FontAwesome name={"paper-plane"} />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default MobileNavbar;
