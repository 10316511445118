import React, { useState, useEffect, Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCommentDots,
  faDollarSign,
  faHeart,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "react-image-gallery";
import FeedContextMenu from "./FeedContextMenu/FeedContextMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { photosPost } from "../redux/actions/postActions";
import StripePayment from "../containers/StripeTest/StripePayment";
import RequestItem from "../containers/Notifications/Calls/RequestItem";
import NotFoundBlock from "../containers/NotFound/NotFoundBlock";

class FeedItemSimple extends Component {

  componentDidMount() {
    let el = document.querySelector('.image-swipe-gallery-' + this.props.feed.id);
    if(el){
      el.addEventListener('dblclick',  () => {
        this.props.like(this.props.feed.id, this.props.user.id);
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          !( this.props.feed.mature === 1 && this.props.user.is_adult_show === 0) &&
          !(this.props.feed.category !== null && this.props.feed.category.mature === 1 && this.props.user.is_adult_show === 0) &&
          ((this.props.feed.paid === 1 &&
            this.props.feed.paidExhibit) ||
            this.props.user.id === this.props.feed.user.id ||
            this.props.feed.paid !== 1)
            ? <div className="profile-account-holder  mb-3  bg-white post-item-list">
            <div className={"post-header p-2 d-flex justify-content-between"}>
              <div className={"d-flex align-items-center"}>
                <NavLink
                  to={this.props.callbackLink}
                  style={{ color: "#000", marginRight: 20, fontSize: 16 }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </NavLink>
                <img
                  src={
                    this.props.settings.mainUrl +
                    "/storage/" +
                    this.props.feed.user.avatar
                  }
                  className={"rounded-circle mr-3"}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.history.push(
                      `/my-profile/highlights/${this.props.feed.user.name}`
                    );
                  }}
                  width={30}
                />
                <div className={"position-relative post-author  d-flex"}>
                  <div className={"font-weight-600"}>
                    {" "}
                    {this.props.feed.user.full_name}
                  </div>
                  <div className={"author-badge"} style={{}}>
                    {
                      this.props.feed.user.badge !== ''? <img src={require(`../assets/img/${this.props.feed.user.badge}.png`)} width={15} />   : null
                    }

                  </div>
                </div>
              </div>
              <div>

                {this.props.feed.category !== null &&
                Object.keys(this.props.feed.category).length > 0 ? (
                  <button className={"btn btn-primary"}>
                    {this.props.feed.category.name}
                  </button>
                ) : null}
              </div>
            </div>
            {/*(this.props.feed.paid === 1 &&
              this.props.feed.paidExhibit) ||
            this.props.user.id === this.props.feed.user.id ||
            this.props.feed.paid !== */ 1 ?
              <div className={"post-image-block"}>
                {this.props.feed.type === 0 ? (
                  <React.Fragment>
                    {this.props.feed.photo.length > 0 ? (
                      <ImageGallery
                        items={this.props.photosPost(this.props.feed,true)}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass={`image-swipe-gallery-${this.props.feed.id}`}
                      />
                    ) : (
                      <img
                        src={
                          this.props.settings.mainUrl +
                          "/storage/" +
                          this.props.feed.image
                        }
                        onDoubleClick={() => {
                          this.props.like(this.props.feed.id, this.props.user.id);
                        }}
                        className={"img-full"}
                      />
                    )}
                  </React.Fragment>
                ) : null}

                {this.props.feed.type === 1 ? (
                  <video
                    className={"img-full"}
                    poster={this.props.feed.image === null || this.props.feed.image === ""
                      ? require("./../assets/img/video-process.png")
                      : this.props.settings.mainUrl +
                      "/storage" +
                      this.props.feed.image}
                    controls={true}
                    src={
                      this.props.settings.mainUrl + "/storage" + this.props.feed.video
                    }
                  />
                ) : null}
              </div>
              : null}

            <div className={"p-3"}>
              <div className={"post-title mb-3 font-weight-700"}>
                {this.props.feed.name}
              </div>
              {/*{this.props.feed.photo.length > 0 ? (*/}
              {/*  <div className={"mb-3"}>*/}
              {/*    {this.props.feed.photo.map((photo, index) => {*/}
              {/*      return (*/}
              {/*        <img*/}
              {/*          style={{ width: "100%" }}*/}
              {/*          key={`feed_image_${index}`}*/}
              {/*          src={this.props.settings.mainUrl + "/storage/" + photo.src}*/}
              {/*        />*/}
              {/*      );*/}
              {/*    })}*/}
              {/*  </div>*/}
              {/*) : null}*/}

              {this.props.feed.description !== "" ? (
                <div className={"mb-3 font-weight-700"} style={{ fontSize: 19 }}>
                  {this.props.feed.description}
                </div>
              ) : null}

              <div className={"post-statuses d-flex justify-content-between mb-3"}>
                <div>
                  <a
                    href={""}
                    className={[
                      "mr-4 ",
                      this.props.feed.likeStars.indexOf(this.props.user.id) !== -1
                        ? "active"
                        : "",
                    ].join(" ")}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.likeStar(this.props.feed.id, this.props.user.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faStar} />{" "}
                    {this.props.feed.likeStars.length}
                  </a>
                  <a
                    href={""}
                    className={[
                      "mr-4 ",
                      this.props.feed.likes.indexOf(this.props.user.id) !== -1
                        ? "active"
                        : "",
                    ].join(" ")}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.like(this.props.feed.id, this.props.user.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faHeart} />{" "}
                    {this.props.feed.likes.length}
                  </a>
                  <a href={""} className={"mr-4"}>
                    <FontAwesomeIcon icon={faCommentDots} />{" "}
                    {this.props.comments.length}
                  </a>
                  <a href={""} className={"mr-4"}>
                    <FontAwesomeIcon icon={faDollarSign} /> {this.props.feed.earning}
                  </a>
                </div>
                <div>
                  <FeedContextMenu
                    feed={this.props.feed}
                    user={this.props.user}
                    postDelete={this.props.postDelete}
                    deleteFromList={() => {
                      this.props.history.push(this.props.callbackLink);
                      // this.deleteFromList(index);
                    }}
                  />
                </div>
              </div>
              <div className={"post-button-block  mb-3"}>
                <button
                  type={"button"}
                  className={"btn btn-bordered-dark btn-block "}
                  onClick={() => {
                    this.props.history.push(this.props.callbackLink);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} /> Back to feed
                </button>
              </div>
              <div className={"mb-3 comment-container"}>
                <div className={"numbers-comments"}>
                  {this.props.feed.commentCount} comments
                </div>
                <div className={"comments-form"}>
                  <input
                    type={"text"}
                    placeholder={"Add a comment..."}
                    value={this.props.comment}
                    onChange={(e) => {
                      this.props.setComment(e.target.value);
                    }}
                  />
                  <img
                    className={"author-img"}
                    src={
                      this.props.settings.mainUrl +
                      "/storage/" +
                      this.props.user.avatar
                    }
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.props.history.push(
                        `/my-profile/highlights/${this.props.user.name}`
                      );
                    }}
                  />
                  <span
                    onClick={() => {
                      this.props.createComment(
                        this.props.comment,
                        this.props.feed.id,
                        this.props.user.id
                      );
                    }}
                  >
                <i className="toolbar-button ion-ios-send"></i>
              </span>
                </div>
                <div className={"comments-list"}>
                  {this.props.comments.map((comment, index) => {
                    return (
                      <div className={"comment-item"} key={`comment_${index}`}>
                        <img
                          src={
                            this.props.settings.mainUrl +
                            "/storage/" +
                            comment.user.avatar
                          }
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.props.history.push(
                              `/my-profile/highlights/${comment.user.name}`
                            );
                          }}
                          className={"comment-img"}
                        />
                        <a href={""} className={"comment-author"}>
                          {comment.user.full_name}
                        </a>
                        <span className={"text-comment"}>{comment.comment}</span>
                      </div>
                    );
                  })}
                </div>
                <a
                  href={""}
                  className={"see-more"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.seeMoreComments(this.props.feed.id);
                  }}
                >
                  See more
                </a>
              </div>
            </div>
          </div>  : <NotFoundBlock/>
        }

      </React.Fragment>

    );
  }
}
function mapStateToProps(state) {
  return {
    settings: state.settings,
    user: state.user.user,
  };
}
function mapDispatchTopPops(dispatch) {
  return {
    photosPost: (feed,status) => {
      return dispatch(photosPost(feed,status));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchTopPops
)(withRouter(FeedItemSimple));
