import React, { Component } from "react";
import DatePicker from "react-date-picker";

class Registration2 extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={[
            "form-group",
            this.props.formErrors().birthDate.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <label>What is your birthday</label>
          <DatePicker
            className={"form-control"}
            onChange={(e) => {
              this.props.onChangeBirthday(e);
            }}
            value={this.props.formData.birthDate}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().birthDate.length > 0 && this.props.isError
              ? this.props.formErrors().birthDate
              : null}
          </div>
        </div>

        <div
          className={[
            "form-group",
            this.props.formErrors().gender.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <select
            className="form-control"
            placeholder="Gender"
            onChange={(e) => {
              this.props.onChangeGender(e);
            }}
            value={this.props.formData.gender}
          >
            <option value={""}>Gender</option>
            <option value={0}>Male</option>
            <option value={1}>Female</option>
          </select>
          <div className={"errors-messages"}>
            {this.props.formErrors().gender.length > 0 && this.props.isError
              ? this.props.formErrors().gender
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().country.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Country"
            onChange={(e) => {
              this.props.onChangeCountry(e);
            }}
            value={this.props.formData.country}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().country.length > 0 && this.props.isError
              ? this.props.formErrors().country
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().city.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="City"
            onChange={(e) => {
              this.props.onChangeCity(e);
            }}
            value={this.props.formData.city}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().city.length > 0 && this.props.isError
              ? this.props.formErrors().city
              : null}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-full-width"
              onClick={this.props.nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Registration2;
