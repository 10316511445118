import React, { Component } from "react";
import LoginRegistration from "../../layouts/LoginRegistration";
import { Alert } from "react-bootstrap";
import { checkErrors } from "./../../functions";
import { connect } from "react-redux";
import { sendForgotPassword } from "./../../redux/actions/userActions";

class ForgotPassword extends Component {
  state = {
    formData: {
      email: "",
    },
    errors: {
      email: [],
    },
    message: {
      text: "",
      type: "",
    },
    isError: false,
  };

  validateForm = () => {
    let errors = {
      email: [],
    };
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.formData.email === "") {
      errors.email.push(<div>email must be required!</div>);
    }
    if (!re.test(this.state.formData.email)) {
      console.log(re.test(this.state.formData.email));
      errors.email.push(<div>email not valid</div>);
    }
    return errors;
  };

  render() {
    return (
      <React.Fragment>
        <LoginRegistration>
          <div className="login-page position-relative">
            <div className="text-content">
              <div className="row">
                <div className="col-lg-7 content"></div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-header text-center">
                <img src={require("../../assets/img/logo.png")} />
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let errors = this.validateForm();
                  let isErrors = checkErrors(errors);
                  if (!isErrors) {
                    this.setState(
                      {
                        isErrors: false,
                      },
                      () => {
                        this.props
                          .sendForgotPassword(this.state.formData.email)
                          .then((response) => {
                            this.setState({
                              message: {
                                text: response.data.message,
                                type: "success",
                              },
                            });
                          })
                          .catch((error) => {
                            this.setState({
                              message: {
                                text: error.response.data.error,
                                type: "danger",
                              },
                            });
                          });
                      }
                    );
                  } else {
                    this.setState({ isErrors: true, errors: { ...errors } });
                  }
                }}
              >
                {this.state.message.length != 0 ? (
                  <Alert variant={this.state.message.type}>
                    {this.state.message.text}
                  </Alert>
                ) : null}

                <div
                  className={[
                    "form-group",
                    this.state.errors.email.length > 0 && this.state.isErrors
                      ? "error"
                      : "",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.formData.email}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          email: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className={"errors-messages"}>
                    {this.state.errors.email.length > 0 && this.state.isErrors
                      ? this.state.errors.email
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-full-width mb-1"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </LoginRegistration>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    sendForgotPassword: (email) => {
      return dispatch(sendForgotPassword(email));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
