import React, { Component } from "react";
import LoginRegistration from "../../layouts/LoginRegistration";
import Registration1 from "./Registration1";
import Registration2 from "./Registration2";
import Registration3 from "./Registration3";
import Registration4 from "./Registration4";
import Registration5 from "./Registration5";
import Registration6 from "./Registration6";
import { connect } from "react-redux";
import axios from "axios";
import { registration, skills } from "./../../redux/actions/userActions";

class Registration extends Component {
  state = {
    stage: 1,
    formData: {
      firstName: "",
      lastName: "",
      password: "",
      username: "",
      phone: "",
      email: "",
      birthDate: "",
      gender: "",
      country: "",
      city: "",

      skills: [
        { category: "", expertise: "" },
        { category: "", expertise: "" },
        { category: "", expertise: "" },
      ],
      photo: "",
      title: "",
      description: "",
    },
    isError: false,
    errors: {
      firstName: [],
      lastName: [],
      username: [<div>username is required!</div>],
      password: [],
      phone: [],
      email: [<div>email is required!</div>],
      birthDate: [],
      gender: [],
      country: [],
      city: [],
      // account_type: [],
      // expertise: [],
      // skills: [],
      photo: [],
      title: [],
      description: [],
    },
    categories: [],
    expertise: [
      { value:'', label:"Expertise"},
      { value:0, label:"Novice"},
      { value:1, label:"Intermediate"},
      { value:2, label:"Expert"}
    ],
  };

  componentDidMount() {
    this.props.skills().then((response) => {
      this.setState({
        categories: [...response.data],
      });
    });
  }

  checkUnique = async (field, value) => {
    let response = await axios.post(
      `${
        this.props.settings.mainUrl
      }/api/check-user-unique/field/${field}/value/${
        value === "" ? "0" : value
      }`
    );
    return response.data;
  };

  validateForm1 = () => {
    let errors = {
      firstName: [],
      lastName: [],
      username: [...this.state.errors.username],
      password: [],
      phone: [],
      email: [...this.state.errors.email],
    };

    if (this.state.formData.firstName === "") {
      errors.firstName.push(<div>First Name is required!</div>);
    }
    if (this.state.formData.lastName === "") {
      errors.lastName.push(<div>Last Name is required!</div>);
    }
    // if (this.state.formData.username === "") {
    //   errors.username.push(<div>username must be required!</div>);
    // }
    if (this.state.formData.password === "") {
      errors.password.push(<div>Password is required!</div>);
    }
    if (this.state.formData.phone === "") {
      errors.phone.push(<div>Phone is required!</div>);
    }
    // if (this.state.formData.email === "") {
    //   errors.email.push(<div>email must be required!</div>);
    // }
    // if (!re.test(this.state.formData.email)) {
    //   errors.email.push(<div>email not valid</div>);
    // }

    return errors;
  };

  validateForm2 = () => {
    let errors = {
      birthDate: [],
      gender: [],
      country: [],
      city: [],
    };
    if (this.state.formData.birthDate === "") {
      errors.birthDate.push(<div>Birthday is required!</div>);
    }
    if (this.state.formData.gender === "") {
      errors.gender.push(<div>Gender is required!</div>);
    }
    if (this.state.formData.country === "") {
      errors.country.push(<div>Country is required!</div>);
    }
    if (this.state.formData.city === "") {
      errors.city.push(<div>City is required!</div>);
    }
    return errors;
  };

  // validateForm3 = () => {
  //   let errors = {
  //     account_type: [],
  //     expertise: [],
  //     skills: [],
  //   };
  //   if (this.state.formData.account_type === "") {
  //     errors.account_type.push(<div>account type must be required!</div>);
  //   }
  //   if (this.state.formData.expertise === "") {
  //     errors.expertise.push(<div>expertise must be required!</div>);
  //   }
  //
  //   return errors;
  // };

  validateForm4 = () => {
    let errors = {
      photo: [],
      title: [],
    };
    if (this.state.formData.photo === "") {
      errors.photo.push(<div>Photo is required.</div>);
    }
    if (this.state.formData.title === "") {
      errors.title.push(<div>Title is required!</div>);
    }
    if (this.state.formData.title.length > 20){
      errors.title.push(<div>Max length field 20 chars!</div>);
    }
    return errors;
  };

  validateForm5 = () => {
    let errors = {
      description: [],
    };

    if (this.state.formData.description === "") {
      errors.description.push(<div>Description is required!</div>);
    }
    return errors;
  };

  validateForm6 = () => {
    let errors = [];
    return errors;
  };

  onChangeFirstNameHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        firstName: e.target.value,
      },
    });
  };
  onChangeLastNameHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        lastName: e.target.value,
      },
    });
  };

  onChangeUsernameHandler = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          username: e.target.value,
        },
      },
      () => {
        this.checkUnique("name", this.state.formData.username).then(
          (response) => {
            let errors = [];

            if (this.state.formData.username === "") {
              errors.push(<div>Username is required!</div>);
            }
            if (response.count !== 0) {
              errors.push(<div>Username not unique!</div>);
            }

            this.setState({
              errors: {
                ...this.state.errors,
                username: [...errors],
              },
            });
          }
        );
      }
    );
  };

  onChangePasswordHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        password: e.target.value,
      },
    });
  };
  onChangePhoneHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phone: e.target.value,
      },
    });
  };
  onChangeEmailHandler = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          email: e.target.value,
        },
      },
      () => {
        this.checkUnique("email", this.state.formData.email).then(
          (response) => {
            let errors = [];
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.state.formData.email === "") {
              errors.push(<div>Username is required!</div>);
            }
            if (response.count !== 0) {
              errors.push(<div>Email not unique</div>);
            }
            if (!re.test(this.state.formData.email)) {
              errors.push(<div>Email not valid</div>);
            }
            this.setState({
              errors: {
                ...this.state.errors,
                email: [...errors],
              },
            });
          }
        );
      }
    );
  };
  onChangeBirthdayHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        birthDate: e,
      },
    });
  };
  onChangeGenderHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        gender: e.target.value,
      },
    });
  };
  onChangeCountryHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        country: e.target.value,
      },
    });
  };
  onChangeCityHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        city: e.target.value,
      },
    });
  };

  onChangeSkillsHandler = (value, index) => {

    let list = [...this.state.formData.skills];
    list[index].category = value;

    this.setState({
      formData: {
        ...this.state.formData,
        skills: [...list],
      },
    });
  };

  onChangeExpertiseHandler = (value, index) => {

    let list = [...this.state.formData.skills];
    list[index].expertise = value;

    this.setState({
      formData: {
        ...this.state.formData,
        skills: [...list],
      },
    });
  };

  onChangePhotoHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        photo: e,
      },
    });
  };

  onChangeTitleHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        title: e.target.value,
      },
    });
  };
  onChangeDescriptionHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        description: e.target.value,
      },
    });
  };
  renderForm = () => {
    switch (this.state.stage) {
      case 1:
        return (
          <React.Fragment>
            <Registration1
              nextPage={this.nextPageHandlerFirstPage}
              onChangeFirstName={this.onChangeFirstNameHandler}
              onChangeLastName={this.onChangeLastNameHandler}
              onChangeEmail={this.onChangeEmailHandler}
              onChangePhone={this.onChangePhoneHandler}
              onChangePassword={this.onChangePasswordHandler}
              onChangeUsername={this.onChangeUsernameHandler}
              onChangeCity={this.onChangeCityHandler}
              formData={this.state.formData}
              formErrors={this.validateForm1}
              isError={this.state.isError}
            />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Registration2
              nextPage={this.nextPageHandlerSecondPage}
              onChangeBirthday={this.onChangeBirthdayHandler}
              onChangeGender={this.onChangeGenderHandler}
              onChangeCountry={this.onChangeCountryHandler}
              onChangeCity={this.onChangeCityHandler}
              formData={this.state.formData}
              formErrors={this.validateForm2}
              isError={this.state.isError}
            />
          </React.Fragment>
        );
      // case 3:
      //   return (
      //     <React.Fragment>
      //       <Registration3
      //         formErrors={this.validateForm3}
      //         nextPage={this.nextPageHandlerThirdPage}
      //         formData={this.state.formData}
      //         onChangeSkills={this.onChangeSkillsHandler}
      //         onChangeAccountType={this.onChangeAccountTypeHandler}
      //         onChangeExpertise={this.onChangeExpertiseHandler}
      //         isError={this.state.isError}
      //       />
      //     </React.Fragment>
      //   );
      case 4:
        return (
          <React.Fragment>
            <Registration4
              formErrors={this.validateForm4}
              nextPage={this.nextPageHandlerForthPage}
              formData={this.state.formData}
              onChangePhoto={this.onChangePhotoHandler}
              onChangeTitle={this.onChangeTitleHandler}
              isError={this.state.isError}
            />
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <Registration5
              formErrors={this.validateForm5}
              nextPage={this.nextPageHandlerFifthPage}
              formData={this.state.formData}
              onChangeDescription={this.onChangeDescriptionHandler}
              isError={this.state.isError}
            />
          </React.Fragment>
        );
      case 6:
        return (
          <React.Fragment>
            <Registration6
              formErrors={this.validateForm6}
              onChangeSkills={this.onChangeSkillsHandler}
              onChangeExpertise={this.onChangeExpertiseHandler}
              expertise={this.state.expertise}
              categories={this.state.categories}
              isError={this.state.isError}
              formData={this.state.formData}
            />
          </React.Fragment>
        );
    }
  };

  nextPageHandlerFirstPage = () => {
    let errors = this.validateForm1();
    this.setState(
      {
        errors: {
          ...this.state.errors,
          ...errors,
        },
      },
      () => {
        let isError = this.checkErrors(this.state.errors);
        if (!isError) {
          this.setState({
            stage: 2,
            isError: false,
          });
        } else {
          this.setState(
            {
              errors: { ...this.state.errors, ...errors },
              isError: true,
            },
            () => {}
          );
        }
      }
    );
  };
  nextPageHandlerSecondPage = () => {
    let errors = this.validateForm2();

    let isError = this.checkErrors(errors);
    if (!isError) {
      this.setState({
        stage: 4,
        isError: false,
      });
    } else {
      this.setState(
        {
          errors: { ...this.state.errors, ...errors },
          isError: true,
        },
        () => {}
      );
    }
  };
  // nextPageHandlerThirdPage = () => {
  //   let errors = this.validateForm3();
  //   console.log(errors);
  //   let isError = this.checkErrors(errors);
  //   if (!isError) {
  //     this.setState({
  //       stage: 4,
  //       isError: false,
  //     });
  //
  //     console.log(this.state.formData);
  //   } else {
  //     this.setState(
  //       {
  //         errors: { ...this.state.errors, ...errors },
  //         isError: true,
  //       },
  //       () => {}
  //     );
  //   }
  // };

  nextPageHandlerForthPage = () => {
    let errors = this.validateForm4();
    let isError = this.checkErrors(errors);
    if (!isError) {
      this.setState({
        stage: 5,
        isError: false,
      });
    } else {
      this.setState(
        {
          errors: { ...this.state.errors, ...errors },
          isError: true,
        },
        () => {}
      );
    }
  };
  nextPageHandlerFifthPage = () => {
    let errors = this.validateForm5();
    let isError = this.checkErrors(errors);
    if (!isError) {
      this.setState({
        stage: 6,
        isError: false,
      });
    } else {
      this.setState(
        {
          errors: { ...this.state.errors, ...errors },
          isError: true,
        },
        () => {}
      );
    }
  };
  nextPageHandlerSixthPage = (e) => {
    e.preventDefault();
    let errors = this.validateForm6();
    let isError = this.checkErrors(errors);
    if (!isError) {
      this.props.registration(this.state.formData).then((response) => {
        this.props.history.push(`/registered/${response.data.id}`);
        // this.props.history("/registered/" + );
      });
    } else {
      this.setState(
        {
          errors: { ...this.state.errors, ...errors },
          isError: true,
        },
        () => {}
      );
    }
  };

  checkErrors = (errors) => {
    let isError = false;
    Object.keys(errors).map((objectKey, index) => {
      let value = errors[objectKey];
      if (value.length > 0) {
        isError = true;
      }
    });
    return isError;
  };

  render() {
    console.log(this.state.formData)
    return (
      <React.Fragment>
        <LoginRegistration>
          <div className="login-page registration-page position-relative">
            <div className="text-content">
              <div className="row">
                <div className="col-lg-7 content"></div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-header text-center">
                <img src={require("../../assets/img/logo.jpg")} />
              </div>
              <form
                onSubmit={(e) => {
                  this.nextPageHandlerSixthPage(e);
                }}
              >
                {this.renderForm()}
              </form>
              <div className="registration-nav">
                <ul>
                  <li className={this.state.stage === 1 ? "active" : ""}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stage: 1,
                        });
                      }}
                    >
                      <i className="circle" />
                    </a>
                  </li>
                  <li className={this.state.stage === 2 ? "active" : ""}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stage: 2,
                        });
                      }}
                    >
                      <i className="circle" />
                    </a>
                  </li>
                  {/*<li className={this.state.stage === 3 ? "active" : ""}>*/}
                  {/*  <a*/}
                  {/*    href={"#"}*/}
                  {/*    onClick={(e) => {*/}
                  {/*      e.preventDefault();*/}
                  {/*      this.setState({*/}
                  {/*        stage: 3,*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <i className="circle" />*/}
                  {/*  </a>*/}
                  {/*</li>*/}

                  <li className={this.state.stage === 4 ? "active" : ""}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stage: 4,
                        });
                      }}
                    >
                      <i className="circle" />
                    </a>
                  </li>
                  <li className={this.state.stage === 5 ? "active" : ""}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stage: 5,
                        });
                      }}
                    >
                      <i className="circle" />
                    </a>
                  </li>
                  <li className={this.state.stage === 6 ? "active" : ""}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stage: 6,
                        });
                      }}
                    >
                      <i className="circle" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          ;
        </LoginRegistration>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return { settings: state.settings };
}

function mapDispatchToProps(dispatch) {
  return {
    registration: (formData) => {
      return dispatch(registration(formData));
    },
    skills: () => {
      return dispatch(skills());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Registration);
