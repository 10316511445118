import React from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function GuestDesktopNavbar() {
  return (
    <Navbar bg={"white"} className={" osahan-nav-top p-0 "} variant="dark">
      <div className={"container-fluid"}>
        <div
          className="row no-gutters align-items-center"
          style={{ width: "100%" }}
        >
          <div className="col pl-4">
            <NavLink
              className="navbar-brand mr-4"
              to={"/"}
              style={{ margin: "7px 0" }}
            >
              <img src={require("../../assets/img/logo.jpg")} />
            </NavLink>
          </div>
        </div>
      </div>
    </Navbar>
  );
}
export default GuestDesktopNavbar;
