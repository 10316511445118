import React, { Component } from "react";

class Registration5 extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={[
            "form-group",
            this.props.formErrors().description.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <label>
            The worlds needs to know what`s up.
            <br />
            Tell us little about yourself!
          </label>
          <textarea
            type="text"
            className="form-control"
            rows={10}
            placeholder={
              "Use this space to give the world a brief history lesson on you. Maybe talk about your mad skills at something, impressive fun facts, your favorite quotes, or just what you like to binge in your free time. Give the world more reasons to fall in love with you!"
            }
            onChange={(e) => {
              this.props.onChangeDescription(e);
            }}
            value={this.props.formData.description}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().description.length > 0 &&
            this.props.isError
              ? this.props.formErrors().description
              : null}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-full-width"
              onClick={this.props.nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Registration5;
