import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserByName } from "../../redux/actions/userActions";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import Masonry from "react-masonry-css";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import { connect } from "react-redux";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import { myHighlights } from "./../../redux/actions/postActions";
import NotFoundBlock from "../NotFound/NotFoundBlock";
import Loader from "../../components/Loader";

class MyProfileHighlights extends Component {
  state = {
    user: {},
    feed: [],
    isLoading:true
  };
  componentDidMount() {
    if (!this.props.match.params.user) {
      this.setState(
        {
          user: { ...this.props.user },
        },
        () => {
          this.props.myHighlights(this.state.user.id).then((response) => {
            this.setState({
              feed: [...response.data],
              isLoading:false
            },() => {
              this.checkDeactivated();
            });
          });
        }
      );
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              this.checkDeactivated();
              this.props.myHighlights(this.state.user.id).then((response) => {
                this.setState({
                  feed: [...response.data],
                  isLoading:false
                });
              });
            }
          );
        });
    }
  }

  checkDeactivated = () => {
    if(this.state.user.status === -1){
      this.props.history.push('/my-profile/about/' + this.state.user.name);
    }
  };


  render() {
    // console.log(this.state.user, this.props.user)
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading}/>
        <DesktopNavbar />
        <MobileNavbar>{this.state.user.name !== this.props.user.name ? `@${this.state.user.name}`  : ' '}</MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile order-1">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.state.user.name}</h2>
                  <MyProfileSidebar
                    active={"highlights"}
                    isOwner={this.state.user.name === this.props.user.name}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    isOwner={this.state.user.name === this.props.user.name}
                    active={{
                      value: "/my-profile/highlights",
                      label: "My Highlights",
                    }}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list order-2"
            style={{ padding: "0 8px" }}
          >
            {this.state.feed.length === 0 ? <NotFoundBlock/>  : null}
            <Masonry
              className={"masonry-gallery"}
              breakpointCols={2}
              columnClassName="masonry-gallery_column"
            >
              {this.state.feed.map((feed, index) => {
                return (
                  <React.Fragment key={`masonry-${index}`}>
                    {!( feed.mature === 1 && this.props.user.is_adult_show === 0) ?   <div
                      className="masonry-gallery-item d"

                    >
                      {feed.type === 0 ? (
                        <img
                          onClick={() => {
                            this.props.history.push(`/my-profile-highlight/${feed.id}`);
                          }}
                          src={
                            this.props.settings.mainUrl + "/storage/" + feed.image
                          }
                        />
                      ) : null}
                      {feed.type === 1 ? (
                        <img
                          className={"img-full"}
                          onClick={() => {
                            this.props.history.push(
                              `/my-profile-highlight/${feed.id}`
                            );
                          }}
                          src={
                            feed.image === null || feed.image === ""
                              ? require("./../../assets/img/video-process.png")
                              : this.props.settings.mainUrl +
                              "/storage" +
                              feed.image
                          }
                        />
                      ) : null}
                    </div> : null}

                  </React.Fragment>

                );
              })}
            </Masonry>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12 order-0">
            <MyProfileUserBlock user={this.state.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    myHighlights: (id_user) => {
      return dispatch(myHighlights(id_user));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfileHighlights);
