import React, { Component } from "react";

class LoginRegistration extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            backgroundImage:
              "url(" + require("../assets/img/Untitled-1.png") + ")",
            backgroundRepeat: "repeat-x",
            backgroundColor: "#e5eaf0",
            height: "100vh",
          }}
        >
          <div className="container ">
            <div className="row">
              {/* Main Content */}
              <main className="col col-xl-12  col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 p-0">
                <div className="   mb-3">
                  <div className="box-body p-3 ">{this.props.children}</div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginRegistration;
